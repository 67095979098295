<template lang="pug">
    section#informacoes
        .wrapper
            ul.itens
                CardInformacao(v-for="item, index in contents.content", :contents="item", :key="index + '-' + item.name.pt")
</template>

<script>
import { components, props } from '@/mixins/components'

export default {
  name: 'section-informacoes',
  props,
  components
}
</script>

<style lang="stylus" scoped src="./Informacoes.styl"></style>
