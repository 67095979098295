import { render, staticRenderFns } from "./Informacoes.vue?vue&type=template&id=0e496c78&scoped=true&lang=pug&"
import script from "./Informacoes.vue?vue&type=script&lang=js&"
export * from "./Informacoes.vue?vue&type=script&lang=js&"
import style0 from "./Informacoes.styl?vue&type=style&index=0&id=0e496c78&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e496c78",
  null
  
)

export default component.exports